<template>
  <div>
    <form-wizard
      color="#236b53"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="addMember"
    >
      <!-- accoint details tab -->
      <tab-content title="Personal Info">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              Personal Info
            </h5>
            <small class="text-muted">
              Enter Your Account Details.
            </small>
          </b-col>
          <b-col md="4">
            <b-form-group label="First Name" label-for="first_name">
              <b-form-input
                id="name"
                v-model="dataInput.firstName"
                placeholder="First Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Middle Name" label-for="middle_name">
              <b-form-input
                id="name"
                v-model="dataInput.middleName"
                placeholder="Middle Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Last Name" label-for="last_name">
              <b-form-input
                id="name"
                v-model="dataInput.lastName"
                placeholder="Last Name"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Address" label-for="address_text">
              <b-form-textarea
                id="address_text"
                v-model="dataInput.address"
                placeholder="Address"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Country" label-for="country">
              <b-form-select 
                id="country"
                v-model="dataInput.country"
                @change="getState()"
                :options="countryOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="State" label-for="state">
              <b-form-select 
                id="state"
                v-model="dataInput.state"
                @change="getCity()"
                :options="stateOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="City" label-for="city">
              <b-form-select
                id="city" 
                v-model="dataInput.city"
                :options="cityOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Email" label-for="email_text">
              <b-form-input
                id="email_text"
                type="email"
                v-model="dataInput.email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Phone Number" label-for="phoneNumber">
              <b-input-group :prepend="dataInput.phoneCode">
                <b-form-input 
                  id="phone"
                  v-model="dataInput.phoneNumber"
                  placeholder="Phone"/>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Gender" label-for="gender">
              <b-form-select 
                id="gender"
                v-model="dataInput.gender"
                :options="genderOptions"/>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Date of Birth" label-for="dateBirth">
              <b-form-input
                id="dateBirth"
                v-model="dataInput.dob"
                type="date"
                placeholder="Date of Birth"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Username"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="dataInput.username"
                placeholder="Username"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- additional info tab -->
      <tab-content title="Additional Info">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              Additional Info
            </h5>
            <small class="text-muted">
              Enter Your Additional Info.
            </small>
          </b-col>
          <b-col md="4">
            <b-form-group label="Bussines Category" label-for="work">
              <b-form-select 
                id="work"
                v-model="dataInput.work"
                :options="workOptions" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Occupation" label-for="occupation">
              <b-form-select 
                id="occupation"
                v-model="dataInput.occupation"
                :options="occupationOptions" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Education" label-for="education">
              <b-form-select 
                id="education"
                v-model="dataInput.education"
                :options="educationOptions" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Provider" label-for="provider">
              <b-form-select 
                id="provider"
                v-model="dataInput.provider"
                :options="providerOptions" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Phone Type" label-for="phoneType">
              <b-form-select 
                id="phoneType"
                v-model="dataInput.phoneType"
                :options="phoneTypeOptions" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Text Savvy"
              label-for="textSavvy"
            >
              <b-form-checkbox
                v-model="dataInput.textSavvy"
                class="custom-control-primary"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import Ripple from 'vue-ripple-directive'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import {
    BRow, BCol,
    BCardBody,
    BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
    BFormGroup, BFormSelect, BFormCheckbox, BFormTextarea, BButton
  } from 'bootstrap-vue'

  export default {
    data() {
      return {
        dataInput: {
          firstName: null,
          middleName: null,
          lastName: null,
          email: null,
          address: null,
          country: null,
          state: null,
          city: null,
          phoneCode: "",
          phoneNumber: null,
          gender: null,
          dob: null,
          work: null,
          occupation: null,
          education: null,
          provider: null,
          phoneType: null,
          username: null,
          textSavvy: null
        },
        genderOptions: [
          { value: 'man', text: 'Man' },
          { value: 'woman', text: 'Woman' }
        ],
        workOptions: [
          { value: 'crafting', text: 'Crafting' },
          { value: 'fashion', text: 'Fashion' },
          { value: 'culinary', text: 'Culinary' },
          { value: 'merchant', text: 'Merchant' },
          { value: 'teacher', text: 'Teacher' },
          { value: 'agricultural', text: 'Agricultural' },
          { value: 'banking', text: 'Banking' },
          { value: 'others', text: 'Others' }
        ],
        occupationOptions: [
          { value: 'chef', text: 'Chef' },
          { value: 'cashier', text: 'Cashier' },
          { value: 'barber', text: 'Barber' },
          { value: 'dentist', text: 'Dentist' },
          { value: 'dentist', text: 'Dentist' },
          { value: 'driver', text: 'Driver' },
          { value: 'teacher', text: 'Teacher' },
          { value: 'tailor', text: 'Tailor' },
          { value: 'mechanic', text: 'Mechanic' },
          { value: 'engineer', text: 'Engineer' },
          { value: 'salesman', text: 'Salesman' },
          { value: 'porter', text: 'Porter' },
          { value: 'doctor', text: 'Doctor' },
          { value: 'employee', text: 'Employee' },
          { value: 'businessman', text: 'Businesman' },
          { value: 'others', text: 'Others' },
        ],
        educationOptions: [
          { value: 'sd', text: 'SD' },
          { value: 'smp', text: 'SMP' },
          { value: 'sma/smk', text: 'SMA/SMK' },
          { value: 'diploma', text: 'Diploma' },
          { value: 'sarjana', text: 'Sarjana' },
          { value: 'pasca sarjana', text: 'Pasca Sarjana' },
        ],
        socioeconomicOptions: [
          { value: 'mikro', text: 'Mikro (< 300 Jt)' },
          { value: 'kecil', text: 'Kecil (300 Jt s/d 2.5 M)' },
          { value: 'menengah', text: 'Kecil (2.5 M s/d 50 M)' },
          { value: 'besar', text: 'Besar (> 50 M)' },
        ],
        providerOptions: [
          { value: 'xl', text: 'XL' },
          { value: 'indosat', text: 'Indosat' },
          { value: 'telkomsel', text: 'Telkomsel' },
          { value: 'byu', text: 'By.U' },
          { value: 'other', text: 'Other' },
        ],
        phoneTypeOptions: [
          { value: 'samsung', text: 'Samsung' },
          { value: 'iphone', text: 'Iphone' },
          { value: 'oppo', text: 'Oppo' },
          { value: 'other', text: 'Other' }
        ],
        countryData: [],
        countryOptions: [],
        stateOptions: [],
        cityOptions: []
      }
    },
    components: {
      FormWizard, TabContent,
      BRow, BCol,
      BCardBody,
      BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend,
      BFormGroup, BFormSelect, BFormCheckbox, BFormTextarea, BButton
    },
		directives: {
			Ripple,
		},
		created() {
      this.checkAuth()
    },
    mounted() {
      this.getCountry()
    },
    methods: {
      getCountry() {
        this.$axios.get('all/csc/countries')
          .then(res => {
            const { data } = res.data
            
            this.countryData = data
            data.forEach(item => {
              let country = {
                value: item.id,
                text: item.name,
              }

              this.countryOptions.push(country)
            })
          })
      },
      getState() {
        let country = this.dataInput.country

        let countryFilter = this.countryData.filter(item => {
          return item.id == country
        })
        this.dataInput.phoneCode = countryFilter[0].phonecode

        this.$axios.get(`all/csc/countries/${country}/states`)
          .then(res => {
            const { data } = res.data

            this.stateOptions = []
            this.cityOptions = []
            data.forEach(item => {
              let state = {
                value: item.id,
                text: item.name
              }

              this.stateOptions.push(state)
            })
          })
      },
      getCity() {
        let state = this.dataInput.state

        this.$axios.get(`all/csc/states/${state}/city`)
          .then(res => {
            const { data } = res.data

            this.cityOptions = []
            data.forEach(item => {
              let city = {
                value: item.id,
                text: item.name
              }

              this.cityOptions.push(city)
            })
          })
      },

      addMember() {
        let cid = localStorage.getItem('client_id')
        let corporateID = localStorage.getItem('corporate_id')
        let data = this.dataInput

        let formData = {
          corporate_id: corporateID,
          first_name: data.firstName,
          middle_name: data.middleName,
          last_name: data.lastName,
          gender: data.gender,
          dob: data.dob,
          provider: data.provider,
          phone_type: data.phoneType,
          work: data.work,
          occupation: data.occupation,
          education: data.education,
          savvy: data.textSavvy,
          email: data.email,
          phone_code: data.phoneCode,
          contact_phone: data.phoneNumber,
          username: data.username,
          address: data.address,
          country: data.country,
          state: data.state,
          city: data.city,
          password: 'password',
          subscribe: false
        }
        this.$axios.post(`${cid}/noauth/member/add`, formData)
          .then(res => {
            // Success code
            this.$bvToast.toast('Success', {
              title: 'Add data member',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-right',
              autoHideDelay: 3000
            })

            setTimeout(() => {
              this.$router.push({ path: '/user/member' })
            }, 3000);
          })
          .catch(error => {
            console.log(error)
          })
      },

      validationForm() {
        return new Promise((resolve, reject) => {
          this.$refs.accountRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      },
    }
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>